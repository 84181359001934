import JMuxer from 'jmuxer'
import * as msgpack from "@msgpack/msgpack";

export default {
    AudioPlayer() {
        let socket;
        let player;
        let element;
        let archive = false;

        function createPlayer(streamId, streamMode, bufferSetting, FPS, _archive) {
            element = document.getElementById(streamId)
            player = new JMuxer({
                node: streamId,
                mode: streamMode,
                clearBuffer: bufferSetting,
                maxDelay: 1000,
                flushingTime: 300,
                fps: FPS,
                debug: false,
                onReady: () => {
                    bindSocket();
                }
            });
            if (archive !== null) {
                archive = _archive;
            }
            return player;
        }

        function createWebSocket(wsuri) {
            socket = new WebSocket(wsuri);
            return socket;
        }

        function setAudioVolume(volume) {
            if (element) {
                element.volume = volume;
            }
        }

        function bindSocket() {
            socket.binaryType = 'arraybuffer';
            socket.addEventListener('message', function (event) {
                if (archive === true) {
                    let frameData = msgpack.decode(new Uint8Array(event.data));
                    player.feed({
                        audio: frameData[5],
                        duration: 64
                    });
                } else {
                    player.feed({
                        audio: new Uint8Array(event.data)
                    });
                }
            });
            socket.addEventListener('close', (event) => {
                //console.log('The connection has been closed successfully.');
            });
            socket.addEventListener('error', function (event) {
                console.error(event.data);
            });
        }

        function resetPlayer() {
            player.reset();
        }

        function tearDown() {
            socket.close();
            player.destroy();
        }

        return {
            createPlayer,
            createWebSocket,
            bindSocket,
            resetPlayer,
            tearDown,
            setAudioVolume
        }
    }
}