<template>
    <div class="d-flex" style="gap: 10px; font-size: 16px">
        <div v-if="playing" class="fa-lg d-flex justify-content-center" style="width: 23px" @click.stop="stopAudio">
            <fa-icon v-if="streamVolume > .50" :icon="['fas', 'volume-up']"/>
            <fa-icon v-else-if="streamVolume > 0" :icon="['fas', 'volume-down']" style=" transform: translate(-4px, 0)"/>
            <div v-else class="d-flex">
                <fa-icon :icon="['fas', 'volume-off']" style="position: relative; transform: translate(-1px, 0)"/>
                <fa-icon :icon="['fas', 'close']" style="font-size: 16px; transform: translate(2px, 2px)"/>
            </div>
        </div>
        <div v-else class="fa-lg d-flex justify-content-center" style="width: 23px" @click.stop="startAudio">
            <div class="d-flex">
                <fa-icon :icon="['fas', 'volume-off']" style="position: relative; transform: translate(-1px, 0)"/>
                <fa-icon :icon="['fas', 'close']" style="font-size: 16px; transform: translate(2px, 2px)"/>
            </div>
        </div>
        <input type="range" v-model="streamVolume" min="0" max="1" step=".05" @mouseenter="toggleDraggable(false)" @mouseleave="toggleDraggable(true)"/>

        <video :id="'audioplayer' + device.getDeviceId() + sourceToken + dataChannel"  style="display: none;" v-if="audioPlayer !== null" width="100%" controls autoplay/>
    </div>

</template>

<script>
import AudioPlayer from "@/shared/jmuxer/audioplayer";
import authStore from "@/store/authStore";
import gammaStore from "@/store/gammaStore";

export default {
    name: "AudioDropdown",
    props: {
        device: Object,
        sourceToken: String,
        dataChannel: String,
    },
    data() {
        return {
            audioPlayer: null,
            playing: false,
            audioStream: this.device.getAudioStream(),
            streamVolume: 0,
            oldStreamVolume: 1
        }
    },
    beforeDestroy() {
        if (this.audioPlayer !== null) {
            this.stopAudio();
        }
    },
    methods: {
        increaseVolume(e) {
            if (e.deltaY > 0 && this.streamVolume > 0) {
                this.streamVolume -= .04;
                if (this.streamVolume < 0) {
                    this.streamVolume = 0;
                }
            } else if (e.deltaY < 0 && this.streamVolume < 1) {
                this.streamVolume += .04;
                if (this.streamVolume > 1) {
                    this.streamVolume = 1;
                }
            }
        },
        toggleDraggable(boolean) {
            this.$emit('toggleDraggable', boolean);
        },
        stopAudio() {
            this.oldStreamVolume = this.streamVolume
            this.streamVolume = 0
            this.playing = false;
            this.audioPlayer.tearDown();
            this.audioPlayer = null;
        },
        restartAudio() {
            if (this.audioPlayer !== null) {
                this.stopAudio();
            }
            this.startAudio();
        },
        async startAudio() {
            this.streamVolume = this.oldStreamVolume === 0 ? 1 : this.oldStreamVolume;
            this.playing = true;
            if (this.audioPlayer !== null) {
                this.audioPlayer.tearDown();
            }
            this.audioPlayer = AudioPlayer.AudioPlayer();
            let stream = this.audioStream;
            let username = await authStore.dispatch('getUserName');
            let gamma = await gammaStore.dispatch('getLiveStreamhub', {
                tenantId: this.device.getTenantId()
            });

            let request = {
                RequestedStream: stream,
                UserName: username,
                TimeStamp: Date.now(),
            }
            let requestId = await gamma.sendMessage('CacheLiveStreamRequestAsync', request)
            let uri = stream.streamServer + "audiosocket?requestId=" + requestId;
            this.audioPlayer.createWebSocket(uri);
            this.audioPlayer.createPlayer('audioplayer' + this.device.getDeviceId() + this.sourceToken + this.dataChannel, 'audio', true, 25);
        }
    },
    watch: {
        streamVolume() {
            if (this.streamVolume !== 0) {
                if (!this.audioPlayer) {
                    this.startAudio()
                }
                this.audioPlayer.setAudioVolume(this.streamVolume);
            } else if (this.playing) {
                this.stopAudio()
            }
        }
    }
}
</script>

<style scoped>

.crossOut {
    position: absolute;
    border-right: 4px solid white;
    height: 16px;
    transform: skewX(45deg) translate(2px, 2px);
}

input[type=range] {
    writing-mode: bt-lr; /* IE */
    width: 60px;
}
i {
    font-size: inherit;
}

</style>