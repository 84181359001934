<template>
    <div class="widgetContainer" :title="viewerList">
        <div/>
        <b style="font-size: 55px; line-height: 1; text-align: center;">
            <fa-icon :icon="['fas', 'user']" class="cursorDefault"/> {{ widgetData.size }}
        </b>
        <div style="font-size: 35px; line-height: .8; text-align: center">Unique</div>
        <div style="font-size: 35px; line-height: .8; text-align: center">Viewer{{widgetData.size === 1 ? "" : "s"}}</div>
    </div>
</template>

<script>
import tenantStore from "@/store/tenantStore";
import deviceStore from "@/store/deviceStore";
import PubSub from "pubsub-js";

export default {
    name: "ViewersWidget",
    data() {
        return {
            tenantData: {},
            widgetData: new Set([]),
            pubsub: null
        }
    },
    async created() {
        // Get Tenants
        let tenants = await tenantStore.dispatch('getTenants');

        // Looping Through Each Constellation
        for (const tenant of tenants) {
            // Get All Unique Viewers
            this.pubsub = PubSub.subscribe('ssdevicesupdate-' + tenant._id, (msg, devices) => {
                this.getViewerCount(tenant, devices);
            });
        }
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.pubsub);
    },
    methods: {
        async getViewerCount(tenant, devices) {
            let viewers = new Set([]);
            for (const device of devices) {
                let dViewers = await deviceStore.dispatch(
                    'getDeviceViewerList',
                    {tenantId: tenant._id, deviceId: device.getDeviceId()}
                );
                viewers = new Set([...viewers, ...dViewers]);
            }
            this.tenantData[tenant._id] = viewers;
            this.makeData();
        },
        makeData() {
            // Combine Constellation Data
            let viewers = new Set([]);
            Object.values(this.tenantData).forEach(tenant => {
                viewers = new Set([...viewers, ...tenant]);
            });
            this.widgetData = viewers;
        }
    },
    computed: {
        viewerList() {
            let arr = Array.from(this.widgetData);
            let res = arr[0] || "";
            for (let i = 1; i < arr.length; i++) {
                res += '\n' + arr[i];
            }
            return res;
        }
    }
}
</script>

<style scoped>
.widgetContainer {
    height: 145px;
    min-width: 200px;
    position: relative;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cursorDefault {
    cursor: default;
}
</style>