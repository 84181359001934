<template>
    <div v-if="userIds" class="d-flex align-items-center" style="font-size: 14px" :title="userIdString">
        <fa-icon :icon="['fas', 'user']" style="font-size: 16px;"/>
        <div style="font-weight: bolder; line-height: 0; font-size: 16px;">&nbsp;{{ userIds.length }}</div>
    </div>
</template>

<script>
import authStore from "@/store/authStore";
export default {
    name: "ViewerCount",
    props: {
        device: Object,
        sourceToken: String
    },
    data () {
        return {
            sub: null,
            user: null
        }
    },
    async created() {
        await authStore.dispatch("getUserName").then(response => {
            this.user = response;
        });
    },
    computed: {
        userIdString() {
            let res = this.userIds[0] || ""
            for (let i = 1; i < this.userIds.length; i++) {
                res += '\n' + this.userIds[i];
            }
            return res;
        },
        userIds() {
            return this.device.getUserIds(this.sourceToken, this.user);
        }
    }
}
</script>