<template>
    <div class="widgetContainer">
        <div class="w-100 d-flex justify-content-end align-items-center">
            <fa-icon :icon="['fas', 'circle']" style="color: red; margin-right: 3px;"/>
            REC&nbsp;
        </div>
        <div class="d-flex flex-column justify-content-between">
            <b style="font-size: 55px; line-height: 1; text-align: center;" >{{ widgetData }}</b>
            <div style="font-size: 32px; line-height: .8; text-align: center">Device{{widgetData === 1 ? "" : "s"}}</div>
            <div style="font-size: 32px; line-height: .8; text-align: center">Recording</div>
        </div>
    </div>
</template>

<script>
import PubSub from "pubsub-js";
import tenantStore from "@/store/tenantStore";
import deviceStore from "@/store/deviceStore";

export default {
    name: "DevicesRecordingWidget",
    data() {
        return {
            constellationData: {},
            widgetData: 0,
            pubsubs: [],
            iter: 0
        }
    },
    async created() {
        await this.getData()
    },
    beforeDestroy() {
        this.pubsubs.forEach(sub => {
            PubSub.unsubscribe(sub);
        });
    },
    methods: {
        async getData() {
            // Get Constellations
            let tenants = await tenantStore.dispatch('getTenants');
            // Looping Through Each Constellation
            for (const tenant of tenants) {
                // Get All Device Status
                this.pubsubs.push(PubSub.subscribe('ssdevicesupdate-' + tenant._id, async (msg, devices) => {
                    this.getRecordingCount(tenant, devices);
                }));
            }
        },
        getRecordingCount(tenant, devices) {
            let devicesRecording = 0
            for (const device of devices) {
                devicesRecording += (device.isRecording() ? 1 : 0);
            }
            this.constellationData[tenant._id] = devicesRecording;
            this.makeData();
        },
        makeData() {
            // Combine Constellation Data
            let devicesRecording = 0;
            Object.values(this.constellationData).forEach(tenant => {
                devicesRecording += tenant;
            });
            this.widgetData =  devicesRecording;
        }
    },
}
</script>

<style scoped>
.widgetContainer {
    height: 145px;
    min-width: 200px;
    position: relative;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>