<template>
    <div class="widgetContainer">
        <div class="pieChartMiddle">
            <b v-if="dataArrived" style="font-size: 55px; line-height: .8">
                {{ widgetData.total }}
            </b>
            <compacting-ring v-else width="45px" thickness="5px" style="margin-bottom: 5px"/>
            <b>Devices</b>
        </div>
        <PieExample :labels="labels" :data="pieData" :colors="colors" :total="widgetData.total" style="height: 270px; width: 450px"/>
    </div>
</template>

<script>
import PieExample from "@/views/template_files/charts/PieExample.vue";
import compactingRing from "@/components/loading_icons/compacting-ring.vue";
import tenantStore from "@/store/tenantStore";
import deviceStore from "@/store/deviceStore";
import PubSub from "pubsub-js";

export default {
    name: "DeviceStatusWidget",
    components: {
        compactingRing,
        PieExample
    },
    data() {
        return {
            constellationData: {},
            constellationDataLoginFailure: {},
            constellations: [],
            widgetData: {
                total: 0,
                up: 0,
                late: 0,
                down: 0,
                loginFailure: 0
            },
            dataArrived: false,
            widgetDataLoginFailure: 0,
            pubsubs: [],
            colors: ['#4dbd74', '#ffc107', '#fd7e14', '#f86c6b'],
            labels:["Up", "Late", "Login Failure", "Down"]
        }
    },
    async created() {
        // Get Constellations
        this.tenants = await tenantStore.dispatch('getTenants');

        // Looping Through Each Constellation
        for (const tenant of this.tenants) {

            // Getting All Devices For One Constellation
            await deviceStore.dispatch('getLoginFailures', tenant._id);

            // Get All Device Status
            this.pubsubs.push(PubSub.subscribe('ssdevicesupdate-' + tenant._id, async (msg, devices) => {
                this.getDeviceStatus(tenant, devices);
            }));
            this.pubsubs.push(PubSub.subscribe('loginfailuresupdate-' + tenant._id, (msg, devices) => {
                this.constellationDataLoginFailure[tenant._id] = devices.length;
            }));
        }
    },
    beforeDestroy() {
        this.pubsubs.forEach(pubsub => {
            PubSub.unsubscribe(pubsub);
        });
    },
    methods: {
        getDeviceStatus(tenant, devices) {
            let deviceStatus = {
                total: 0,
                up: 0,
                late: 0,
                down: 0,
                loginFailure: 0
            };
            deviceStatus.total = devices.length;
            for (const device of devices) {
                switch (device.getDetectionStatus()) {
                    case 0:
                        deviceStatus.up++;
                        break;
                    case 1:
                        deviceStatus.late++;
                        break;
                    case 2:
                        deviceStatus.down++;
                        break;
                    default:
                        deviceStatus.loginFailure++;
                }
            }
            this.constellationData[tenant._id] = deviceStatus;
            this.makeData();
            this.dataArrived = true;
        },
        makeData() {
            // Combine Tenant Data
            let deviceStatus = {
                total: 0,
                up: 0,
                late: 0,
                down: 0,
                loginFailure: 0
            };
            Object.entries(this.constellationData).forEach(tenant => {
                let loginFailures = this.constellationDataLoginFailure[tenant[0]] || 0;
                deviceStatus.total += tenant[1].total + loginFailures;
                deviceStatus.up += tenant[1].up;
                deviceStatus.late += tenant[1].late;
                deviceStatus.down += tenant[1].down;
                deviceStatus.loginFailure += tenant[1].loginFailure + loginFailures;
            });
            this.widgetData = deviceStatus;
        }
    },
    computed: {
        pieData() {
            return [this.widgetData.up, this.widgetData.late, this.widgetData.loginFailure, this.widgetData.down];
        }
    }

}
</script>

<style scoped>
.widgetContainer {
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
}
.pieChartMiddle {
    position: absolute;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    background-color: inherit;
    transform: translate(2px, 75px);
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
</style>