<template>
    <div class="d-flex flex-column justify-content-between" v-if="device" :id="'videoControls-' + sourceId"
         :draggable="this.zoomedIn === false && videoWall === true"
         @dragstart="setDeviceDragData"
         @mouseenter="hover = true; edgeListenerOpen();"
         @wheel="mouseScrolled"
         @mousemove.self="storeCoords"
         @mousedown.self="clickDown"
         @mouseup.self="clickUp"
         @mouseleave.passive="hover = false; mouseDown = false;"
         @click="pauseFullScreen"
         style="font-size: 16px; position: relative">

        <transition name="icon">
            <fa-icon v-if="iconShowing" :icon="['fas', isPaused === true ? 'play' : 'pause']" :id="'pauseIcon'+sourceId"
                     class="centered-icon" :style="{fontSize: calculateIconSize()+'px'}"/>
        </transition>

        <div style="position: absolute">
            <MiniStreamWindow :device="device" :sourceId="sourceId"/>
        </div>

        <div v-if="!hover" class="h-100 w-100 d-flex justify-content-center align-items-end textShadow" style="position: absolute; font-size: 12px; user-select: none;">
            <div class="d-flex align-items-center" style="gap: 3px">
                <fa-icon v-if="device.isRecording()" :icon="['fas', 'circle']"
                   style="color: red; font-size: 10px; transform: translateY(-1px); text-shadow: none"/>
                {{device.error === 'device' ? 'Error' : getTitle() || 'Waiting...' }}
            </div>
        </div>

        <transition name="fade">
            <div class="d-flex justify-content-between align-items-start" v-if="hover || qualityMenu"
                 :style="hover || qualityMenu ? 'background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))' : ''"
                 style="padding: 10px; height: 40px">

                <!-- Title/ViewerCount -->
                <div class="d-flex align-items-center" style="gap: 10px; user-select: none;">
                    <div style="font-weight: bolder; line-height: 0">
                        <fa-icon v-if="device.isRecording()" :icon="['fas', 'circle']"
                           style="color: red; font-size: 14px; transform: translateY(-1px)"/>
                        {{device.error === 'device' ? 'Error' : getTitle() || 'Waiting...' }}
                    </div>
                    <ViewerCount v-if="!playback" :sourceToken="sourceToken" :device="device" style="transform: translateY(-1px)" bounce/>
                    <div v-else style="height: 12px; width: 23px"/>
                </div>
                <!-- StreamOptions/Close -->
                <transition name="fade">
                    <div v-if="!fullscreen" class="d-flex align-items-center" style="gap: 15px">
                        <fa-icon v-if="!archiveOnly && !isStreamOptions && videoWall && (showStreamOptions === true || isEdgeListenerOpen === true)"
                                 icon="fa-solid fa-sliders" style="cursor: pointer;" title="Stream Options" @click.stop="openStreamOptions"/>
                        <fa-icon v-else-if="!archiveOnly && videoWall && (showStreamOptions === true || isEdgeListenerOpen === true)"
                                 :icon="['far', 'circle-left']" style="cursor: pointer; font-size: 18px;" title="Close Stream Options"
                                 @click.stop="closeStreamOptions"/>
                        <fa-icon v-if="!isStreamOptions" :icon="['fas', 'close']" style="cursor: pointer; font-size: 18px;"
                                 title="Close Stream" @click.stop="closeStream"/>
                    </div>
                </transition>

            </div>
        </transition>
        <transition name="fade">
            <div v-show="hover || qualityMenu">
                <div style="padding: 10px; height: 40px"
                     :style="hover || qualityMenu ? 'background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6))' : ''"
                     class="d-flex justify-content-between align-items-end">

                    <!-- Volume slider -->
                    <AudioDropdown v-if="archiveOnly === false && device.isAudioDevice()" :sourceToken="sourceToken" :dataChannel="dataChannel"
                                   :device="device" @toggleDraggable="toggleDraggable"/>
                    <div v-else/>

                    <!-- Quality/Fullscreen -->
                    <div class="d-flex align-items-center" style="gap: 15px">
                        <fa-icon v-if="archiveOnly === false && isPlayingBack === false"  :icon="['fas', 'cog']" style="cursor: pointer;" class="position-relative"
                           :id="'streamQuality' + device.getDeviceId() + sourceToken + dataChannel"
                           @click.stop="qualityMenu = !qualityMenu" title="Change Quality"/>
                        <div v-if="archiveOnly === false && isPlayingBack === false && qualityMenu" class="position-absolute rounded"
                             style="background-color: black; padding: 5px"
                             :style="'transform: translate(-21px, -' + (qualities.length === 3 ? 50 : (qualities.length === 2 ? 40 : 30)) + 'px)'">
                            <div class="d-flex flex-column align-items-center" style="width: 43px">
                                <div style="cursor: pointer; font-size: 12px" v-for="option in device.getStreamQualities(sourceToken, dataChannel)"
                                     @click.stop="selectQuality(option)"
                                     :style="'color: ' + (option.value === selectedQuality ? 'white' : 'grey')">
                                    {{ option.text }}
                                </div>
                            </div>
                        </div>
                        <fa-icon v-if="fullscreen" title="Normal Screen" :icon="['fas', 'compress']" style="cursor: pointer;" @click.stop="exitFullscreen"/>
                        <fa-icon v-else title="Full Screen" :icon="['fas', 'expand']" style="cursor: pointer;" @click.stop="goFullscreen"/>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import ViewerCount from "@/views/video_wall/ViewerCount";
import AudioDropdown from "@/views/video_wall/AudioDropdown";
import deviceStore from "@/store/deviceStore";
import PubSub from "pubsub-js";
import eleQueries from "css-element-queries";
import MiniStreamWindow from "@/views/video_wall/MiniStreamWindow.vue";

export default {
    name: "PlayerControls",
    props: {
        device: Object,
        containerIDPrefix: String,
        isStreamOptions: {
            type: Boolean,
            default: true
        },
        playback: {
            type: Boolean,
            default: false
        },
        videoWall: {
            type: Boolean,
            default: true
        },
        sourceToken: {
            type: String,
            default: '0'
        },
        dataChannel: {
            type: String,
            default: undefined
        },
        archiveOnly: {
            type: Boolean,
            default: false,
        },
        isGridPreset: {
            type: Boolean,
            default: false
        },
        showStreamOptions: {
            type: Boolean,
            default: true
        },
        quality: {
            type: Number,
            default: deviceStore.getters.getQuality
        }
    },
    components: {
        AudioDropdown,
        ViewerCount,
        MiniStreamWindow
    },
    data: () => {
        return {
            qualities: [],
            selectedQuality: "Low",
            video: null,
            playing: false,
            fullscreen: false,
            hover: false,
            qualityMenu: false,
            doubleClickId: null,
            isPlayingBack: false,
            mouseDown: false,
            mouseCoords: {},
            mouseDownCoords: {},
            isEdgeListenerOpen: false,
            isPaused: false,
            iconShowing: false,
            isOpenStreamOptions: false,
            zoomedIn: false
        }
    },
    created() {
        document.addEventListener('fullscreenchange', function() {
            if (document.fullscreenElement === null) {
                this.fullscreen = false;
            }
        }.bind(this));
        PubSub.subscribe('isPlayingBack' + this.device.getDeviceId() + this.sourceToken + this.dataChannel, (msg, boolean) => {
            this.isPlayingBack = boolean;
        });
        if (this.archiveOnly === false) {
            this.qualities = this.device.getStreamQualities(this.sourceToken, this.dataChannel);
        }
    },
    mounted() {
        if (this.archiveOnly === false) {
            let streamQualities = this.device.getStreamQualities(this.sourceToken, this.dataChannel).map(_ => _.value);
            let targetQuality = this.quality;
            let quality;
            if (streamQualities.includes(targetQuality)) {
                quality = targetQuality;
            } else {
                let lowerNumbers = streamQualities.filter(number => number < targetQuality).sort((a, b) => b - a);
                if (lowerNumbers[0] !== undefined) {
                    quality = lowerNumbers[0];
                } else {
                    let higherNumbers = streamQualities.filter(number => number > targetQuality).sort((a, b) => b + a);
                    if (higherNumbers[0] !== undefined) {
                        quality = higherNumbers[0];
                    } else {
                        console.error("cannot find stream quality");
                        quality = targetQuality;
                    }
                }
            }
            this.selectedQuality = quality;
        }
        new eleQueries.ResizeSensor(document.getElementById('videoControls-' + this.sourceId), () => {
            clearTimeout(this.resizeTimeout)
            this.resizeTimeout = setTimeout(() => {
                this.resizeIcon();
            }, 500);
        });
        this.resizeIcon();
    },
    beforeDestroy() {
        PubSub.unsubscribe('isPlayingBack' + this.device.getDeviceId() + this.sourceToken + this.dataChannel);
    },
    methods: {
        setDeviceDragData(event) {
            if (this.device.isZoomedIn() === false) {
                event.dataTransfer.setData('text', JSON.stringify({
                    dataChannel: this.dataChannel,
                    sourceToken: this.sourceToken,
                    quality: this.selectedQuality,
                    isStream: true,
                    moveStream: true
                }));
            }
        },
        resizeIcon() {
            let root = document.documentElement;
            root.style.setProperty('--font-size', (document.getElementById('videoControls-' + this.sourceId).offsetHeight / 7).toString() + 'px');
        },
        calculateIconSize() {
          let container = document.getElementById('videoControls-' + this.sourceId);
          if (container !== null) {
              return container.offsetHeight / 15;
          } else {
              return 0;
          }
        },
        getTitle() {
            if (this.device.getMultiSensor() > 1) {
                return this.device.getDeviceStreamName(this.dataChannel, this.sourceToken);
            } else {
                return this.device.getDeviceName();
            }
        },
        mouseScrolled(e) {
            //mouse scroll down, zoom out
            if (this.device.getClickPTZ() === false) {
                //this.device.changeShowMiniCanvas(this.sourceId, this.isStreamOptions);
                if (this.isGridPreset === true || this.isOpenStreamOptions === true) {
                    this.device.changeDraggable(this.sourceId, true)
                }
                if (e.deltaY > 0) {
                    this.device.zoomOut(this.sourceId, e.layerX, e.layerY);
                    if (this.device.isZoomedIn(this.sourceId) === false) {
                        this.zoomedIn = false;
                    }
                } else if (e.deltaY < 0) {
                    //mouse scroll up, zoom in
                    this.zoomedIn = true;
                    this.device.zoomIn(this.sourceId, e.layerX, e.layerY);
                }
            }
        },
        clickDown(e) {
            if (this.device.isDraggable(this.sourceId) === true) {
                this.mouseCoords = {x: e.layerX, y: e.layerY};
                this.mouseDownCoords = {x: e.layerX, y: e.layerY};
                if (this.device.getCanvasZoom(this.sourceId) !== 0) {
                    this.mouseDown = true;
                }
            }
        },
        clickUp(e) {
            if (this.mouseCoords.x === e.layerX && this.mouseCoords.y === e.layerY && this.device.getClickPTZ() === true) {
                let canvas = document.getElementById('video_canvas_' + this.sourceId);
                let video = document.getElementById('gamma-video-' + this.sourceId);
                let videoWidth = video.videoWidth, videoHeight = video.videoHeight;
                let fovSpace = this.device.getPTZStatus(this.sourceId).extendedSpaces.find(_ => _.ptzSpaceType === 2 && _.ptzTranslationType === 2);
                let offsetWidth = this.device.getCanvasWidth(this.sourceId) > canvas.width - 3 ? 0 : (canvas.width - this.device.getCanvasWidth(this.sourceId))/2;
                let offsetHeight = this.device.getCanvasHeight(this.sourceId) >  canvas.height - 3 ? 0 : (canvas.height - this.device.getCanvasHeight(this.sourceId))/2;
                let canvasWidth = canvas.width - (offsetWidth * 2), canvasHeight = canvas.height - (offsetHeight * 2);
                let videoX = ((this.mouseCoords.x - offsetWidth) / canvasWidth) * videoWidth;
                let videoY = ((this.mouseCoords.y - offsetHeight) / canvasHeight) * videoHeight;
                if (fovSpace !== undefined) {
                    let moveX = videoX - (videoWidth/2);
                    let moveY = (videoY - (videoHeight/2)) * -1;
                    let correctedX = (moveX > 0 ? ((moveX/(videoWidth/2)) * fovSpace.xRange.max) : ((moveX/(videoWidth/2)) * (fovSpace.xRange.min * -1)));
                    let correctedY = (moveY > 0 ? ((moveY/(videoHeight/2)) * fovSpace.yRange.max) : ((moveY/(videoHeight/2)) * (fovSpace.yRange.min * -1)));
                    this.device.PTZClickMove(this.sourceId, correctedX, correctedY, fovSpace.schemaUri);
                }
            } else if (this.mouseDownCoords.x === e.layerX && this.mouseDownCoords.y === e.layerY) {
                if (this.device.isLive(this.sourceId) === false) {
                    this.device.isPaused(this.sourceId);
                    this.isPaused = this.device.isPaused(this.sourceId);
                    this.$nextTick(() => {
                        this.iconShowing = true;
                        this.$nextTick(() => {
                            this.iconShowing = false;
                        });
                    });
                }
                if (this.device.isPaused(this.sourceId) === true && this.device.isLive(this.sourceId) === false) {
                    this.device.play(this.sourceId);
                } else if (this.device.isPaused(this.sourceId) === false && this.device.isLive(this.sourceId) === false) {
                    this.device.pause(this.sourceId);
                }
            }
            this.mouseDown = false;
        },
        async storeCoords(e) {
            if (this.mouseDown === true && this.device.getClickPTZ() === false) {
                if (e.layerX !== this.mouseCoords.x || e.layerY !== this.mouseCoords.y) {
                    let differenceX = this.mouseCoords.x - e.layerX;
                    let differenceY = this.mouseCoords.y - e.layerY;
                    this.device.dragCanvas(this.sourceId, differenceX, differenceY);
                }
            }
            this.mouseCoords = {x: e.layerX, y: e.layerY};
            await this.edgeListenerOpen();
        },
        selectQuality(quality) {
            this.selectedQuality = quality.value;
            PubSub.publish('quality-change-' + this.sourceId, this.selectedQuality)
            //this timeout just leaves the quality select menu open a little longer to inform the user of the change
            setTimeout(() => {
                this.qualityMenu = false;
            }, 1000);
        },
        async goFullscreen() {
            await document.getElementById(this.containerIDPrefix + this.sourceId).requestFullscreen();
            this.resizeIcon();
            this.fullscreen = true;
        },
        async exitFullscreen() {
            await document.exitFullscreen();
            this.resizeIcon();
            this.fullscreen = false;
        },
        openStreamOptions() {
            this.$emit('openStreamOptions');
            this.isOpenStreamOptions = true;
            this.zoomedIn = false;
            this.device.changeDraggable(this.sourceId, true);
        },
        closeStreamOptions() {
            this.$emit('closeStreamOptions');
            this.isOpenStreamOptions = false;
            if (this.isGridPreset === false) {
                this.device.changeDraggable(this.sourceId, false);
            }
            this.device.changeShowMiniCanvas(this.sourceId, false);
            this.device.setClickPTZ(false);
            this.device.closeEventConnection(this.sourceId);
            this.device.resetVideoSize(this.sourceId, true, false);
            this.zoomedIn = false;
            //attempt to go back to live play if using playback
            this.device.stopPlayback(this.sourceId);
        },
        closeStream() {
            //this can go either to the video wall for infinity grid or to grid preset view
            this.$emit('close');
        },
        toggleDraggable(bool) {
            this.$emit('toggleDraggable', bool);
        },
        findAudioStream() {
            if (this.device.getStreams() !== undefined) {
                return this.device.getStreams().findIndex(_ => _.streamType === SmartSuiteArchive.streamTypes.Audio) !== -1;
            } else {
                return false;
            }
        },
        async pauseFullScreen() {
            if (this.doubleClickId === null) {
                this.doubleClickId = setTimeout(() => {
                    this.doubleClickId = null;
                }, 250);
            } else {
                if (this.fullscreen === true) {
                    await this.exitFullscreen();
                    this.device.resetVideoSize(this.sourceId, true);
                } else {
                    await this.goFullscreen();
                    this.device.resetVideoSize(this.sourceId, true);
                }
                this.doubleClickId = null;
            }
        },
        async edgeListenerOpen() {
            this.isEdgeListenerOpen = await deviceStore.dispatch('checkForEdgeListener', {
                tenantId: this.device.getTenantId(),
                edgeService: this.device.getHubName()
            });
        },
    },
    computed: {
        sourceId() {
            return this.device.getDeviceId() + this.sourceToken + this.dataChannel;
        }
    },
    watch: {
        isStreamOptions(newVal, oldVal) {
            this.device.changeShowMiniCanvas(this.sourceId, newVal);
        }
    }
}
</script>

<style scoped>
:root {
    --font-size: 120px;
}

.centered-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.textShadow {
    text-shadow:
    -1px -1px 2px rgba(0, 0, 0, 0.5),
    1px -1px 2px rgba(0, 0, 0, 0.5),
    -1px 1px 2px rgba(0, 0, 0, 0.5),
    1px 1px 2px rgba(0, 0, 0, 0.5);
    font-weight: bold
}

.icon-enter-active,
.icon-leave-active {
    transition: all 1.5s ease;
}

.icon-leave-to {
    opacity: 0;
    font-size: var(--font-size) !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

i {
    font-size: inherit
}

</style>