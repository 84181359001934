<template>
    <div class="widgetContainer">
        <div class="pieChartMiddle">
            <b style="font-size: 30px; line-height: .8">{{ total }}GB</b>
            <b>Storage</b>
        </div>
        <PieExample @hover.prevent :labels="labels" :data="data" :colors="colors" :total="total" style="height: 270px; width: 450px"/>
    </div>
</template>

<script>
import PieExample from "@/views/template_files/charts/PieExample.vue";

export default {
    name: "StorageWidget",

    components: {
        PieExample
    },
    data() {
        return {
            data: [2500, 500],
            total: 3000,
            labels: ["GB Used", "GB Avalible"],
            colors: ['#20a8d8', '#666666']
        }
    },
}
</script>

<style scoped>
.widgetContainer {
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
}
.pieChartMiddle {
    position: absolute;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    background-color: inherit;
    transform: translate(2px, 75px);
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
</style>